/* eslint-disable no-param-reassign */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { Trans } from '@lingui/react'
import { css } from 'styled-components'
import { Bar, BarItem, Button, Select, Icon, BarProps } from '../../ui-kit'
import { TablePaginationStore } from './stores/TablePaginationStore'

const countOptions = [
  { text: '5', value: '5' },
  { text: '10', value: '10' },
  { text: '20', value: '20' },
  { text: '30', value: '30' },
  { text: '40', value: '40' },
  { text: '50', value: '50' },
]

const countOptionsExtended = [
  ...countOptions,
  { text: '100', value: '100' },
  { text: '200', value: '200' },
  { text: '300', value: '300' },
]

export const TablePagination = observer(
  ({
    store,
    extendedOptions,
    ...other
  }: { store: TablePaginationStore; extendedOptions?: boolean } & BarProps) => {
    return (
      <Bar
        align="middle"
        {...other}
        css={css`
          background-color: white;
          padding: 0px 24px 8px 24px;
          border: 1px solid #e9e9e9;
          margin-top: 0;
          border-top: 0;
        `}>
        <BarItem data-testid="count-per-page">
          <Select
            transparent
            value={String(store.perPage)}
            size="small"
            id="count-per-page"
            options={extendedOptions ? countOptionsExtended : countOptions}
            onChange={val => {
              store.perPage = Number(val)
            }}
          />
        </BarItem>
        {store.objectCount !== 0 && (
          <>
            <BarItem className="text-small text-color-grey">
              <Trans>
                {store.firstRow}-{Math.min(store.lastRow, store.objectCount)} z {store.objectCount}{' '}
                položiek
              </Trans>
            </BarItem>
            <BarItem isFilling />

            <>
              <BarItem className="text-small text-color-grey">
                <Trans>
                  {store.page} z {store.numPages} stránok
                </Trans>
              </BarItem>
              <BarItem>
                <Button
                  equal
                  size="small"
                  isDisabled={store.page === 1}
                  onClick={_ => {
                    store.page -= 1
                  }}>
                  <Icon size="medium" name="chevron-left" />
                </Button>
              </BarItem>
              <BarItem data-testid="current-page">
                <Select
                  transparent
                  value={String(store.page)}
                  size="small"
                  isDisabled={store.numPages < 2}
                  id="page"
                  options={store.pagesOptions}
                  onChange={val => {
                    store.page = Number(val)
                  }}
                />
              </BarItem>
              <BarItem>
                <Button
                  equal
                  size="small"
                  data-testid="next-page"
                  isDisabled={store.page === store.numPages}
                  onClick={_ => {
                    store.page += 1
                  }}>
                  <Icon size="medium" name="chevron-right" />
                </Button>
              </BarItem>
            </>
          </>
        )}
      </Bar>
    )
  }
)
