import { computed, observable } from 'mobx'
import { each, isEqual, omitBy, template } from 'lodash-es'

export class FilterExpressionBuilder<T> {
  /**
   * map of <filter name, querystring>
   * @example
   * code=*Brat*&&name=foo*||address.postcode>1
   */
  private filters = observable.map<T, string>()

  /**
   * template for building final `querystring` replacing `filter name's` with their values and
   * connecting them with operators
   *
   * NOTE: use single quotes for template, not literal!!!
   * @example
   * given filters `foo` and `bar`, template like '${foo}||${bar}' will produce
   * foo=value||bar=value
   */
  finalExpressionTemplate = ''

  /**
   * defaults used when reseting to initial state
   */
  private defaultFilters = observable.map<T, string>()

  constructor(filterNames: [T, string][], exprTemplate: string) {
    this.filters.replace(filterNames)
    this.defaultFilters = observable.map(filterNames)
    this.finalExpressionTemplate = exprTemplate
  }

  getFilter(key: T) {
    return this.filters.get(key)
  }

  setFilter(key: T, value = '') {
    this.filters.set(key, value)
  }

  resetToDefaults() {
    this.filters.replace(this.defaultFilters)
  }

  /**
   * check if any filter is applied
   */
  @computed get isAnyFilterActive() {
    let isActive = false
    for (const val of this.filters.values()) {
      if (val !== '') {
        isActive = true
        break
      }
    }
    return isActive
  }

  /**
   * check if applied filters are different then defaults
   */
  @computed get isDirty() {
    return !isEqual(this.filters, this.defaultFilters)
  }

  /**
   * check if applied filters are same as defaults
   */
  @computed get isNotDirty() {
    return isEqual(this.filters, this.defaultFilters)
  }

  clearAll() {
    for (const key of this.filters.keys()) {
      this.setFilter(key, '')
    }
  }

  @computed get querystring() {
    // eslint-disable-next-line no-template-curly-in-string
    const compiled = template(this.finalExpressionTemplate)

    const filters = this.filters.toPOJO()

    each(filters, (val, key) => {
      if (val !== '' && val.split('=').length - 1 > 1) filters[key] = `(${val})`
    })

    let replaced = compiled(filters)

    // remove forward, trailing pipes && if there's more than 2 pipes
    replaced = replaced.replace(/(&&|\|\|)+$/g, '')
    replaced = replaced.replace(/^(&&|\|\|)+/g, '')
    replaced = replaced.replace(/(\|\|){2,}/g, '||')
    replaced = replaced.replace(/(&&){2,}/g, '&&')

    return replaced === '' ? '' : `"${replaced}"`
  }

  @computed get params() {
    return omitBy<{ filter: string }>({ filter: this.querystring }, i => i === '')
  }
}

export function createSearchFilter(fieldsToSearchIn: string[], term: string) {
  return term
    .split(' ')
    .map(t => {
      return `(${fieldsToSearchIn.map(f => `${f}=*${t}*`).join('||')})`
    })
    .join('&&')
}
